const breakpoints = {
  'xs':375,
  'sm':576,
  'md':768,
  'lg':992,
  'xl':1200,
  '2xl':1440
}
jQuery(document).ready(function ($) {
  /* Mobile navbar functionnality */
  // Show submenu when a navbar item is clicked 
  $(".nav__menu-item").not(".nav__menu-item--back").on("click", function(e){
    e.preventDefault();
    $(".nav__menu-item").not($(this)).removeClass("nav__menu-item--active").addClass("nav__menu-item--hidden");
    $(".nav__menu-item--back").removeClass("nav__menu-item--hidden");
    $(this).addClass("nav__menu-item--active");
  });
  // Stop propagating click event when a submenu item is clicked
  $(".nav__menu-subitem").on("click", function(e){
    e.stopPropagation();
    $("body").removeClass("no-scroll");
    $(".nav").removeClass("nav--active");
  });
  // Go to main menu when back link is clicked 
  $(".nav__menu-item--back").on("click", function(e){
    $(".nav__menu-item").removeClass("nav__menu-item--active").removeClass("nav__menu-item--hidden");
    $(this).addClass("nav__menu-item--hidden");
  });
  // Open/Close menu when hamburger icon is clicked
  $(".nav__toggle").on("click", function(e){
    $(".nav").toggleClass("nav--active");
    $("body").toggleClass("no-scroll");
  })
  
  /* Desktop navbar functionnality */
  $(".lg-nav__menu-item").hover(function(e){
    $(this).find(".lg-nav__sub").addClass("lg-nav__sub--active");
  }, function(e){
    $(this).find(".lg-nav__sub").removeClass("lg-nav__sub--active");
  });
  $("window").resize(function(e){
    if($(window).width() >= breakpoints.xl){
      $("body").removeClass("no-scroll")
    }
  });
});