const breakpoints = {
  'xs':375,
  'sm':576,
  'md':768,
  'lg':992,
  'xl':1200,
  '2xl':1440
}
jQuery(document).ready(function ($) {
  /* Hero slider */
  $(".hero__slider").slick({
    slidesToShow:1,
    prevArrow:$(".hero__slider-arrow--prev"),
    nextArrow:$(".hero__slider-arrow--next"),
    infinite:false,
    autoplay:true,
    autoplaySpeed:4000,
  });
  $(".hero__slider").on("beforeChange", function(slick, event, currentSlide, nextSlide){
    $(".hero__navigation-item").removeClass("hero__navigation-item--active");
    if(nextSlide == 0 || nextSlide == 1){
      $(".hero__navigation-item").eq(0).addClass("hero__navigation-item--active");
    }
    else{
      $(".hero__navigation-item").eq(nextSlide - 1).addClass("hero__navigation-item--active");
    }
  });
  $(".hero__navigation-item").on("click", function(e){
    e.preventDefault();
    $(".hero__slider").slick('slickGoTo',$(this).data('index'));
  });
  /* Logos slider */ 
  $(".logos-list__container").slick({
    arrows:false,
    centerMode: true,
    centerPadding: '20%',
    responsive:[
      {
        breakpoint: breakpoints.sm,
        settings:{
          slidesToShow:2
        }
      },
      {
        breakpoint: breakpoints.md,
        settings:{
          slidesToShow:3
        }
      },
      {
        breakpoint: breakpoints.lg,
        settings:{
          slidesToShow:4,
          arrows:false,
          centerMode: true,
          centerPadding: '20%',
        }
      },
      {
        breakpoint: 10000,
        settings:"unslick"
      }
    ]
  });
  /* Topics slider */
  $(".topics__container").slick({
    centerMode:true,
    centerPadding:'24%',
    arrows:false,
    responsive:[
      {
        breakpoint:breakpoints.sm,
        settings:{
          slidesToShow:1,
        }
      },
      {
        breakpoint:breakpoints.md,
        settings:{
          slidesToShow:2,
          centerPadding:'15%',
        }
      },
      {
        breakpoint:breakpoints.lg,
        settings:{
          slidesToShow:3,
          centerPadding:'10%',
        }
      },
      {
        breakpoint:10000,
        settings:"unslick"
      },
    ]
  });
  /* Ig feed slider */ 
  $(".ig-feed__list").slick({
    arrows:false,
    centerMode:true,
    responsive:[
      {
        breakpoint: breakpoints.sm,
        settings:{
          slidesToShow:1,
          centerPadding:'5%',
        }
      },
      {
        breakpoint: breakpoints.md,
        settings:{
          slidesToShow:2,
          centerPadding:'5%',
        }
      },
      {
        breakpoint: breakpoints.lg,
        settings:{
          slidesToShow:3,
          centerPadding:'5%',
        }
      },
      {
        breakpoint: 10000,
        settings:{
          slidesToShow:4,
          centerPadding:'0%',
        }
      }
    ]
  });
  /*  Article images slider */
  $(".article__images").each((ind, elm) => {
    elm = $(elm);
    elm.slick({
      slidesToShow:1,
      infinite:false,
      prevArrow:elm.next().find(".article__images-arrow--prev"),
      nextArrow:elm.next().find(".article__images-arrow--next")
    })
  });
  /* Products feed slider */ 
  $(".products-feed__slider").slick({
    prevArrow:$(".products-feed__arrow--prev"),
    nextArrow:$(".products-feed__arrow--next"),
    responsive:[
      {
        breakpoint: breakpoints.md,
        settings:{
          slidesToShow:1
        }
      },
      {
        breakpoint: breakpoints.lg,
        settings:{
          slidesToShow:2
        }
      },
      {
        breakpoint: breakpoints.xl,
        settings:{
          slidesToShow:3
        }
      },
      {
        breakpoint: 10000,
        settings:{
          slidesToShow:4,
          centerMode:true,
        }
      }
    ]
  });
  /* Products feed 2 slider */ 
  $(".products-feed2__slider").slick({
    slidesToShow:1,
    prevArrow:$(".products-feed2__slider-arrow--prev"),
    nextArrow:$(".products-feed2__slider-arrow--next"),
    infinite:false
  });
  /* Our picks product slider  */
  $(".product--left .product__slider").each(function(ind,elm){
    elm = $(elm);
    elm.slick({
      prevArrow:elm.next().find(".product__slider-arrow--prev"),
      nextArrow: elm.next().find(".product__slider-arrow--next"),
      infinite:false,
      responsive:[
        {
          breakpoint: breakpoints.xl,
          settings:{
            slidesToShow:1
          }
        },
        {
          breakpoint: 10000,
          settings:{
            slidesToShow:2
          }
        }
      ]
    });
  });
  $(".product--right .product__slider").each(function(ind,elm){
    elm = $(elm);
    elm.slick({
      prevArrow:elm.next().find(".product__slider-arrow--prev"),
      nextArrow: elm.next().find(".product__slider-arrow--next"),
      infinite:false,
      initialSlide:2,
      responsive:[
        {
          breakpoint: breakpoints.xl,
          settings:{
            slidesToShow:1
          }
        },
        {
          breakpoint: 10000,
          settings:{
            slidesToShow:2
          }
        }
      ]
    });
  });
  /* Partners slider */
  $(".partners__slider").slick({
    centerMode:true,
    arrows:false,
    responsive:[
      {
        breakpoint: breakpoints.md,
        settings:{
          slidesToShow:1,
          centerPadding:'6%'
        }
      },
      {
        breakpoint:breakpoints.xl,
        settings:{
          slidesToShow:2,
          centerPadding:'3%'
        }
      },
      {
        breakpoint:10000,
        settings:"unslick"
      }
    ]
  });
  $(".hiw__slider").slick({
    arrows:false,
    centerMode:true,
    infinite:false,
    responsive:[
      {
        breakpoint:breakpoints.lg,
        settings:{
          slidesToShow:1,
          centerPadding:'25%',
        }
      },
      {
        breakpoint:10000,
        settings:"unslick"
      }
    ]
  });
  $(".hiw2__slider").slick({
    arrows:false,
    centerMode:true,
    infinite:false,
    responsive:[
      {
        breakpoint:breakpoints.lg,
        settings:{
          slidesToShow:1,
          centerPadding:'25%',
        }
      },
      {
        breakpoint:10000,
        settings:"unslick"
      }
    ]
  });
  $(".plans__slider").slick({
    arrows:false,
    centerMode:true,
    infinite:false,
    // dots: true,
    responsive:[
      {
        breakpoint:breakpoints.lg,
        settings:{
          slidesToShow:1,
          centerPadding:'25%',
        }
      },
      {
        breakpoint:10000,
        settings:"unslick"
      }
    ]
  });
  $(".testimonials__slider").slick({
    slidesToShow:1,
    arrows:false,
    dots: true,
    dotsClass:'testimonials__slider-dots',
    responsive:[
      {
        breakpoint:breakpoints.lg,
        settings:{
          vertical:false,
          verticalSwiping:false
        }
      },
      {
        breakpoint:10000,
        settings:{
          vertical:true,
          verticalSwiping:true
        }
      }
    ]
  });
});
/* Press publications slider */
$(".articles-list__slider").slick({
    prevArrow:$(".articles-list__btn--prev"),
    nextArrow:$(".articles-list__btn--next"),
    dots:true,
    appendDots:$(".articles-list__slider-dots"),
    infinite:false,
    responsive:[
      {
        breakpoint:breakpoints.lg,
        settings:{
          vertical:true,
          slidesToShow:3,
          slidesToScroll:3,
        }
      },
      {
        breakpoint:10000,
        settings:{
          slidesToShow:3,
          slidesToScroll:3,
        }
      }
    ]
});