$("#submit-swap__form").submit(function(e){
  e.preventDefault();
  $(".submit-swap__form-section .message").remove();
  const isConfirmed = $("#confirm").is(":checked");
  if(isConfirmed){
    $(".submit-swap__form-btn-icon").addClass("submit-swap__form-btn-icon--active");
    let formData = new FormData($(this)[0]);
    formData.append("action",'contact-form/send');
    $.ajax({
      url:'/web',
      data: formData,
      type: 'POST',
      dataType: 'json',
      contentType: false,
      processData: false,
      beforeSend: function() {
        $(".submit-swap__form-btn-icon").addClass("submit-swap__form-btn-icon--active");
        $(".submit-swap__form-group").prop("disabled", true);
      }
    }).always(function(){
      $(".submit-swap__form-btn-icon").removeClass("submit-swap__form-btn-icon--active");
      $(".submit-swap__form-group").prop("disabled", false);
    }).done(function(response){
      if(response?.errors){
        for(const [key,value] of Object.entries(response.errors)){
          value.forEach((elm) => {
            $(".submit-swap__form-section").last().append(
              `
              <p class='message error'>
              ${elm}
              </p>
            `
            )
          });
        }
      }
      else{
        window.location.href = "swap-thanks";
      }
    }).fail(function(response){
      alert("Something went wrong. Please try again later.");
    });
  }
  else{
    $(".submit-swap__form-section").last().append(
      "<p class='message error'>Please confirm that the items are as described according to the terms and conditions</p>"
    )
  }
});