// Toggle topics search sidebar
$(".journal-header__search").on("click", function(e){
  $(".journal-header__topics").addClass("journal-header__topics--active");
  $("body").addClass("no-scroll");
});
$(".journal-header__topics-header-btn").on("click", function(e){
  $(".journal-header__topics").removeClass("journal-header__topics--active");
  $("body").removeClass("no-scroll");
});
// Open ifram video when play button is clicked (Discover more section)
$(".discover-more__play").on("click", function(e){
  $(".discover-more__modal").addClass("discover-more__modal--active");
  $("body").addClass("no-scroll");
});
$(".discover-more__modal").on("click", function(e){
  if($(this).hasClass("discover-more__modal--active")){
    $(this).removeClass("discover-more__modal--active");
    const video = $(".discover-more__modal-video");
    video.attr("src", video.attr("src")); 
    $("body").removeClass("no-scroll");
  }
});
// Open ifram video when play button is clicked (Media article section)
$(".article__header-right .article__type").on("click", function(e){
  $(".player").click();
});
$(".player").on("click", function(e){
  $([document.documentElement, document.body]).animate({
    scrollTop: $(".player").offset().top
  }, 500);

  setTimeout(function(){
    $(".player__modal").addClass("player__modal--active");
    $(".article--about").addClass("article--modal")
    $("body").addClass("no-scroll");
  }, 500)
});
$(".player__modal").on("click", function(e){
  e.stopPropagation();
  if($(this).hasClass("player__modal--active")){
    $(this).removeClass("player__modal--active");
    $(".article--about").removeClass("article--modal");
    const video = $(".player__modal-video");
    video.attr("src", video.attr("src"));
    $("body").removeClass("no-scroll");
  }
});
// play ig video on hover 
$(".ig-feed__list-item-video").hover(function(e){
  const video = $(this)[0];
  video.muted = true;
  video.currentTime = 1;
  video.play();

}, function(e){
  const video = $(this)[0];
  video.currentTime = 0;
  video.pause();
});
/*  Toggle product tabs when tab header is clicked (Mobile) */
$(".product-tabs__item-header").on("click", function(e){
  $(this).parents(".product-tabs__item").toggleClass("product-tabs__item--active");
});

/* Toggle product tabs when tab button is clicked (Desktop) */
$(".product-tabs__button").on("click", function(e){
  $(".product-tabs__button").not($(this)).removeClass("product-tabs__button--active");
  $(this).addClass("product-tabs__button--active");
  e.preventDefault();
  const index = $(this).data('index');
  $(`.product-tabs__tab[data-index=${index}]`).removeClass("disabled-tab");
  $(`.product-tabs__tab[data-index!=${index}]`).addClass("disabled-tab");
});
/* Toggle faq when toggle icon is clicked */
$(".faqs__icon").on("click", function(e){
  $(this).parents(".faqs__item").toggleClass("faqs__item--active");
});
/* Add file name to the file card when a file is selected */
$(".submit-swap__form-file-input").on("change", function(e){
  const fileName = $(this)[0].files[0].name;
  $(this).parents(".submit-swap__form-file").find(".submit-swap__form-file-label").text(fileName);
});
/* Remove empty paragraphs from articles */
$(".article__text p").each(function(){
  if($(this).text() == ''){
    $(this).remove();
  }
});
/* Add divider line between content sections in articles */
$(".article__body--divider .article__text").find("h1,h2,h3,h4,h5").each(function(){
  $(this).prev("p").addClass("divider");
});
/* Open swap modal after 3 seconds */
setTimeout(function(){
  if($(".swap__modal").length > 0){
    $("body").addClass("no-scroll");
    $(".swap__modal").addClass("swap__modal--active");
  }
},3000);

function closeModal(){
  $(".swap__modal").removeClass("swap__modal--active");
  $("body").removeClass("no-scroll");
  window.localStorage.setItem("modalAlreadyOpened", "true");
}
/* Close swap modal when close button is clicked */
$(".swap__modal-close").on("click", function(e){
  closeModal();
});
/* Close swap modal when form is submitted */
$(".swap__modal-form").on("submit", function(e){
  closeModal();
});

/* Animation bootcode */
setTimeout(function(){
$('.animated:in-viewport').addClass('animate');
},1000);
$(window).on('scroll', function() {
  $('.animated:in-viewport').addClass('animate');
});
